import { useTranslations } from 'next-intl';

import type { TFaqListData } from '@/common';
import { IconButton } from '@/components/atoms';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/molecules/Accordion';

type TFaqProps = {
  questionCount?: number;
};
function Faq({ questionCount = 6 }: Readonly<TFaqProps>) {
  const t = useTranslations();

  const first6Questions = t.raw('faqListData').slice(0, questionCount) as TFaqListData[];

  return (
    <div className="w-full bg-bg-body">
      <div className="container py-10 sm:py-20">
        <h2 className="h1 mb-6 text-center sm:mb-10">{t('frequentlyAskedQuestions')}</h2>
        <div className="flex flex-col">
          <Accordion type="single" collapsible className="w-full">
            {first6Questions.map((faq) => (
              <AccordionItem value={faq.question} key={faq.question} className="border-b">
                <AccordionTrigger className=" subtitle2-bold justify-between py-4 text-left">
                  {faq.question}

                  <IconButton
                    as="div"
                    icon="right-chevron"
                    className="accordion-chevron shrink-0 transition-transform duration-200"
                    color="neutral"
                    variant="outlined"
                  />
                </AccordionTrigger>
                <AccordionContent className="pb-4">{faq.answer}</AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      </div>
    </div>
  );
}

export { Faq };
