import { useTranslations } from 'next-intl';
import Image from 'next/image';

function GiftOccasions() {
  const t = useTranslations();

  const giftOccasionsData = t.raw('giftOccasionsData') as string[];
  return (
    <section className="w-full">
      <div className="container py-10">
        <div className="flex flex-col items-start gap-6 sm:gap-10 lg:flex-row">
          <div className="max-w-[560px] flex-grow">
            <Image
              className="w-full object-contain"
              src="/images/pages/business/gift-occasions.webp"
              width="660"
              height="620"
              alt="why wait mobile image"
              loading="lazy"
            />
          </div>

          <div className="flex w-full flex-col gap-4 sm:w-[360px] sm:gap-6">
            <div className="flex flex-col gap-2 sm:gap-4">
              <h3 className="h1">{t('giftOccasionsTitle')}</h3>
            </div>
            <ul className="flex list-disc flex-col gap-2 pl-6 sm:gap-6">
              {giftOccasionsData.map((occasion) => (
                <li key={occasion} className="items-center">
                  <p>{occasion}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export { GiftOccasions };
