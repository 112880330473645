import { useTranslations } from 'next-intl';

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@/components/atoms';

import { getLink } from '@/utils/getLink';
import { HowCanWeHelp, NeedMoreAsistance, RequestADemoForm } from '../molecules';

type TContactUsContentProps = Readonly<{
  isAuthenticated: boolean;
}>;
export function ContactUsContent({ isAuthenticated }: TContactUsContentProps) {
  const t = useTranslations();

  return (
    <div className="w-full bg-bg-default">
      <div className="container max-w-[1920px] pt-4">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink href={getLink('home')}>{t('home')}</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>{t('contactUs')}</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>

        <div className="mx-auto flex max-w-6xl flex-col gap-10 py-10 lg:flex-row lg:gap-24">
          {isAuthenticated ? (
            <>
              <HowCanWeHelp isAuthenticated={true} />
              <NeedMoreAsistance className="w-full lg:max-w-[560px]" />
            </>
          ) : (
            <>
              <HowCanWeHelp isAuthenticated={false} />
              <RequestADemoForm />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
