import { Button, Carousel, CarouselContent, CarouselItem } from '@/components/atoms';
import { cn } from '@/lib/utils';
import { getLink } from '@/utils/getLink';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import Link from 'next/link';

function GiftVariants() {
  const t = useTranslations();

  const giftVariantsData = t.raw('giftVariants') as { title: string; brand: string; url: string }[];

  return (
    <div className="w-full bg-bg-body">
      <div className="px-4 py-10 lg:px-[120px]">
        <h2 className="h1 mb-6 text-center sm:mb-10">{t('giftVariantsTitle')}</h2>
        <Carousel className="w-full max-w-full" hasDots>
          <CarouselContent className="-ml-6 sm:-ml-10">
            {giftVariantsData.map((brand) => (
              <CarouselItem
                key={brand.title}
                className={cn(
                  'w-[184px] max-w-[184px] px-0 pl-6 lg:w-[200px] lg:max-w-[200px] lg:pl-10'
                )}
              >
                <Link
                  href={getLink('search', { searchTerm: brand.brand })}
                  prefetch={false}
                  shallow={true}
                >
                  <div className="mb-4 overflow-hidden rounded-full bg-blue-50 p-[0.625rem] duration-150">
                    <Image
                      src={`/images/pages/business/gift-variants/${brand.title}.webp`}
                      alt="carousel image"
                      width={400}
                      height={400}
                      className="transition-transform duration-300 group-hover:scale-110"
                    />
                  </div>

                  <div className="text-center">
                    <p className="subtitle2-bold capitalize">{brand.title}</p>
                    <p>{brand.brand}</p>
                  </div>
                </Link>
              </CarouselItem>
            ))}
          </CarouselContent>
        </Carousel>

        <div className="mt-6 flex w-full items-center justify-center">
          <Button
            aria-label={t('shopGifts')}
            href={getLink('allProducts')}
            as={'a'}
            className="mx-auto w-full sm:w-fit"
            size="lg"
          >
            {t('shopGifts')}
          </Button>
        </div>
      </div>
    </div>
  );
}

export { GiftVariants };
