import { useTranslations } from 'next-intl';
import Link from 'next/link';

import { Button, Icon } from '@/components/atoms';
import { getLink } from '@/utils/getLink';

function Download() {
  const t = useTranslations();

  return (
    <div className="w-full bg-navy-500">
      <div className="container flex flex-col justify-center gap-4 py-3 text-fg-inverse-default sm:flex-row sm:items-center sm:gap-8">
        <div className="flex items-center gap-4">
          <Icon
            size="lg"
            name="pdf"
            className="h-[52px] w-[52px] rounded-full bg-neutral-500 bg-opacity-20 p-2"
          />
          <p className="body1-regular">{t('downloadText')}</p>
        </div>
        <Button
          as={Link}
          href={getLink('downloadForBusiness')}
          target="_blank"
          rel="noopener noreferrer"
          color="white"
          variant="outlined"
          size="sm"
          className="w-full sm:w-fit"
          icon={<Icon name="download" size="sm" />}
          iconPosition="left"
          download
          aria-label={t('downloadText')}
        >
          {t('download')}
        </Button>
      </div>
    </div>
  );
}

export { Download };
